import NotFound from './not-found';
import { default as SnaqAccountDetails } from './snaq/account/details';
import { default as SnaqAccountLogin } from './snaq/account/login';
import { default as SnaqAccountOrdersHistory } from './snaq/account/orders';
import { default as SnaqAccountRegister } from './snaq/account/register';
import { default as SnaqResetPassword } from './snaq/account/reset-password';
import BrandFooterTastic from './snaq/brands/brand-footer';
import BrandHeroTastic from './snaq/brands/brand-hero';
import BrandLinksTastic from './snaq/brands/brand-links';
import BrandListTastic from './snaq/brands/brand-list';
import BrandSelectionTastic from './snaq/brands/brand-selection';
import BreadcrumbsTastic from './snaq/breadcrumbs';
import { default as SnaqCart } from './snaq/cart';
import CategoryArticlesTastic from './snaq/categories/category-articles';
import CategoryExpertsTastic from './snaq/categories/category-experts';
import CategoryHeroTastic from './snaq/categories/category-hero';
import CategorySelectionsTastic from './snaq/categories/category-selections';
import CategorySeoTastic from './snaq/categories/category-seo';
import CategorySetsTastic from './snaq/categories/category-sets';
import CategoryTitleTastic from './snaq/categories/category-title';
import SubCategoryListTastic from './snaq/categories/subcategory-list';
import ContactFormTastic from './snaq/contact-form';
import ButtonTastic from './snaq/content/button';
import { default as SnaqCategoryTeasers } from './snaq/content/category-teasers';
import ExpertButtonTastic from './snaq/content/expert-button';
import ExpertListTastic from './snaq/content/expert-list';
import ExpertTeasersTastic from './snaq/content/expert-teasers';
import HeroTastic from './snaq/content/hero';
import HeroSliderTastic from './snaq/content/hero-slider';
import LinksBannerTastic from './snaq/content/links-banner';
import MarkdownTastic from './snaq/content/markdown';
import MarketingPopupTastic from './snaq/content/marketing-popup';
import MediaImageTastic from './snaq/content/media-image';
import MediaTextSliderTastic from './snaq/content/media-text-slider';
import MediaYoutubeTastic from './snaq/content/media-youtube';
import PageListTastic from './snaq/content/page-list';
import PageTeasersTastic from './snaq/content/page-teasers';
import PageTeasersSliderTastic from './snaq/content/page-teasers-slider';
import ProductSpecialDetailsTastic from './snaq/content/product-special-details';
import ProfileTeasersTastic from './snaq/content/profile-teasers';
import Spacer from './snaq/content/spacer';
import TableTastic from './snaq/content/table';
import TestimonialsTastic from './snaq/content/testimonials';
import TextTastic from './snaq/content/text';
import TextAndMediaTastic from './snaq/content/text-and-media';
import TextAndMediaTwiceTastic from './snaq/content/text-and-media-twice';
import TextOnMediaTastic from './snaq/content/text-on-media';
import { default as SnaqFooter } from './snaq/footer';
import { default as SnaqHeader } from './snaq/header';
import { default as SnaqMultistepCheckout } from './snaq/multistep-checkout';
import { default as SnaqNewsletter } from './snaq/newsletter';
import AssociatedProductsTastic from './snaq/products/associated-products';
import { default as SnaqProductDetails } from './snaq/products/details';
import { default as FactfinderSimilarProductsTastic } from './snaq/products/factfinder-similar-products';
import ProductHighlightsTastic from './snaq/products/product-highlights';
import { default as SnaqProductList } from './snaq/products/product-list';
import { default as SnaqProductListPreselected } from './snaq/products/product-list-preselected';
import ProductReviewTastic from './snaq/products/product-review';
import ProductSelectionTastic from './snaq/products/product-selection';
import ProductsComparisonTastic from './snaq/products/products-comparison';
import { default as SnaqSearchResult } from './snaq/products/search-result';
import { default as SnaqSimilarProducts } from './snaq/products/similar-products';
import { default as SnaqProductSlider } from './snaq/products/slider';
import { default as StripeReturn } from './snaq/stripe-return';
import { default as SnaqCookieDeclaration } from './snaq/utils/cookie-declaration';
import { default as SnaqWishlist } from './snaq/wishlist';

export const tastics = {
  'snaq/ui/header': SnaqHeader,
  'snaq/ui/breadcrumbs': BreadcrumbsTastic,
  'snaq/ui/footer': SnaqFooter,
  'snaq/ui/brands/brand-footer': BrandFooterTastic,
  'snaq/ui/brands/brand-hero': BrandHeroTastic,
  'snaq/ui/brands/brand-links': BrandLinksTastic,
  'snaq/ui/brands/brand-selection': BrandSelectionTastic,
  'snaq/ui/brands/brand-list': BrandListTastic,
  'snaq/ui/content/button': ButtonTastic,
  'snaq/ui/content/hero': HeroTastic,
  'snaq/ui/content/text': TextTastic,
  'snaq/ui/content/table': TableTastic,
  'snaq/ui/content/hero-slider': HeroSliderTastic,
  'snaq/ui/content/category-teasers': SnaqCategoryTeasers,
  'snaq/ui/content/expert-button': ExpertButtonTastic,
  'snaq/ui/content/expert-list': ExpertListTastic,
  'snaq/ui/content/expert-teasers': ExpertTeasersTastic,
  'snaq/ui/content/profile-teasers': ProfileTeasersTastic,
  'snaq/ui/content/page-teasers': PageTeasersTastic,
  'snaq/ui/content/page-teasers-slider': PageTeasersSliderTastic,
  'snaq/ui/content/product-special-details': ProductSpecialDetailsTastic,
  'snaq/ui/content/page-list': PageListTastic,
  'snaq/ui/content/testimonials': TestimonialsTastic,
  'snaq/ui/content/marketing-popup': MarketingPopupTastic,
  'snaq/ui/content/media-text-slider': MediaTextSliderTastic,
  'snaq/ui/content/links-banner': LinksBannerTastic,
  'snaq/ui/content/text-on-media': TextOnMediaTastic,
  'snaq/ui/content/text-and-media': TextAndMediaTastic,
  'snaq/ui/content/text-and-media-twice': TextAndMediaTwiceTastic,
  'snaq/ui/content/markdown': MarkdownTastic,
  'snaq/ui/content/media-image': MediaImageTastic,
  'snaq/ui/content/media-youtube': MediaYoutubeTastic,
  'snaq/ui/content/newsletter': SnaqNewsletter,
  'snaq/ui/content/spacer': Spacer,
  'snaq/ui/contact-form': ContactFormTastic,
  'snaq/ui/categories/category-articles': CategoryArticlesTastic,
  'snaq/ui/categories/category-experts': CategoryExpertsTastic,
  'snaq/ui/categories/category-hero': CategoryHeroTastic,
  'snaq/ui/categories/category-seo': CategorySeoTastic,
  'snaq/ui/categories/category-sets': CategorySetsTastic,
  'snaq/ui/categories/category-selections': CategorySelectionsTastic,
  'snaq/ui/categories/category-title': CategoryTitleTastic,
  'snaq/ui/categories/subcategory-list': SubCategoryListTastic,
  'snaq/ui/products/products-comparison': ProductsComparisonTastic,
  'snaq/ui/products/product-list': SnaqProductList,
  'snaq/ui/products/product-list-preselected': SnaqProductListPreselected,
  'snaq/ui/products/search-result': SnaqSearchResult,
  'snaq/ui/products/details': SnaqProductDetails,
  'snaq/ui/products/slider': SnaqProductSlider,
  'snaq/ui/products/factfinder-similar-products': FactfinderSimilarProductsTastic,
  'snaq/ui/products/similar-products': SnaqSimilarProducts,
  'snaq/ui/products/product-highlights': ProductHighlightsTastic,
  'snaq/ui/products/product-review': ProductReviewTastic,
  'snaq/ui/products/product-selection': ProductSelectionTastic,
  'snaq/ui/products/associated-products': AssociatedProductsTastic,
  'snaq/ui/account/register': SnaqAccountRegister,
  'snaq/ui/account/login': SnaqAccountLogin,
  'snaq/ui/account/reset-password': SnaqResetPassword,
  'snaq/ui/account/details': SnaqAccountDetails,
  'snaq/ui/account/orders': SnaqAccountOrdersHistory,
  'snaq/ui/utils/cookie-declaration': SnaqCookieDeclaration,
  'snaq/ui/wishlist': SnaqWishlist,
  'snaq/ui/cart': SnaqCart,
  'snaq/ui/multistep-checkout': SnaqMultistepCheckout,
  'snaq/stripe/return': StripeReturn,
  default: NotFound,
};
